
import {OrderManagerRepository} from "@/modules/orderManager/adapter/OrderManagerRepository";

export class OrderManagerPort implements OrderManagerRepository{
    public async sendArticle(mode: number): Promise<boolean> {
        return this.orderManagerRepository.sendArticle(mode);
    }

    public async sendCustomers(mode: number): Promise<boolean> {
        return this.orderManagerRepository.sendCustomers(mode);
    }
    public async receiveTaf(): Promise<boolean> {
        return this.orderManagerRepository.receiveTaf();
    }
    public async receivePersonalzeiten(): Promise<boolean> {
        return this.orderManagerRepository.receivePersonalzeiten();
    }
    public async sendMasterdata(mode: number): Promise<boolean> {
        return this.orderManagerRepository.sendMasterdata(mode);
    }

    public async sendScaleData(mode: number): Promise<boolean> {
        return this.orderManagerRepository.sendScaleData(mode);
    }
    private readonly orderManagerRepository: OrderManagerRepository;

    constructor(params: { orderManagerRepository: OrderManagerRepository }) {
        this.orderManagerRepository = params.orderManagerRepository;
    }
    public async getConnectionStatus():Promise<number> {
        return this.orderManagerRepository.getConnectionStatus();
    }
    public async getBadges(appId:string):Promise<{APPID:string, APPAREA:string,COLOR:string,BADGETEXT:string,EXTENSIVETEXT:string}[] | undefined> {
        return this.orderManagerRepository.getBadges(appId);
    }
    public async isTransmitting():Promise<boolean> {
        return this.orderManagerRepository.isTransmitting();
    }

    public async getReports() : Promise<any[]> {
        return this.orderManagerRepository.getReports();
    }

    public async getReport(args:{reportNr:number, start:Date, end:Date}): Promise<string> {
        return this.orderManagerRepository.getReport(args);
    }

    public async getCashReport(date:string, id:number): Promise<string> {
        return this.orderManagerRepository.getCashReport(date,id);
    }

    public async getBonReport(id:number): Promise<string> {
        return this.orderManagerRepository.getBonReport(id);
    }

    public async sendArticleList(selected:{RecordId:number}[]): Promise<boolean> {
        return this.orderManagerRepository.sendArticleList(selected);
    }

    public getSalesGridList(): any[] {
        return this.orderManagerRepository.getSalesGridList();
    }

    public getProductReport(args: { reportNr: number; start: Date; end: Date }): Promise<string> {
        return this.orderManagerRepository.getProductReport(args);
    }

    public getProductReports(id: number): Promise<any[]> {
        return this.orderManagerRepository.getProductReports(id);
    }

    public async receiveDeliveryNotes(): Promise<boolean> {
        return this.orderManagerRepository.receiveDeliveryNotes();
    }

    public async goodsReceipts(date:string): Promise<any[]> {
        return this.orderManagerRepository.goodsReceipts(date);
    }

    public async receiptGoodReceipt(recordId: number, date:string, goodReceipt:number): Promise<string> {
        return this.orderManagerRepository.receiptGoodReceipt(recordId,date,goodReceipt);
    }
    public async createDeliveryNotes(ids:number[]): Promise<string> {
        return this.orderManagerRepository.createDeliveryNotes(ids);
    }

    public async pickUpReady(ids:number[]): Promise<string> {
        return this.orderManagerRepository.pickUpReady(ids);
    }
    public async cashUpDeliveryNotes(ids:number[]): Promise<string> {
        return this.orderManagerRepository.cashUpDeliveryNotes(ids);
    }

    public async getOrderManagerReport(id:string): Promise<string> {
        return this.orderManagerRepository.getOrderManagerReport(id);
    }
    public async getOrderManagerReportInvoice(id:string): Promise<string> {
        return this.orderManagerRepository.getOrderManagerReportInvoice(id);
    }
    public async cashUpBills(ids:number[]): Promise<string> {
        return this.orderManagerRepository.cashUpBills(ids);
    }

    public async acceptOrderQuantities(ids:{Nummer:number}[]): Promise<string> {
        return this.orderManagerRepository.acceptOrderQuantities(ids);
    }

    public async createOrderByArticles(ids:{Nummer:number}[]): Promise<any> {
        return this.orderManagerRepository.createOrderByArticles(ids);
    }
}
