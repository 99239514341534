import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import axios from "axios";
import moment from "moment-mini";
import {OrderManagerRepository} from "@/modules/orderManager/adapter/OrderManagerRepository";

export class OrderManagerRestRepository implements OrderManagerRepository {
    private readonly auth: AuthenticationProvider;

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }

    private salesList = [ {
            "LABEL" : "Auswertungen",
            "ICON" : "ordermanager-presentation-business",
            "GRIDNAME" : "",
            "FORMNAME" : "",
            "DISABLED" : false
          },
            {
                "LABEL" : "Bons",
                "ICON" : "ordermanager-bill-credit-card",
                "GRIDNAME" : "",
                "FORMNAME" : "",
                "DISABLED" : false
            },
            {
                "LABEL" : "Kassenberichte",
                "ICON" : "ordermanager-cash-register",
                "GRIDNAME" : "",
                "FORMNAME" : "",
                "DISABLED" : false
            }];

    public async receiveTaf(): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/receive_TAF`, data);

            if (response.data.receive_TAF_response?.ServiceErrors) {
                throw new Error(response.data.receive_TAF_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.receive_TAF_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async receivePersonalzeiten(): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/receive_Personalzeiten`, data);

            if (response.data.receive_Personalzeiten_response?.ServiceErrors) {
                throw new Error(response.data.receive_Personalzeiten_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.receive_Personalzeiten_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async sendCustomers(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Kunden`, data);

            if (response.data.send_Kunden_response?.ServiceErrors) {
                throw new Error(response.data.send_Kunden_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Kunden_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async getConnectionStatus(): Promise<number> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserID: userId,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_connection_status`, data);

            if (response.data.get_connection_status_response?.ServiceErrors) {
                throw new Error(response.data.get_connection_status_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            // nicht verbunden=0,
            // inaktiv=1,
            // aktiv=2,
            // Connect-Dienst nicht bereit=3

            return response.data.get_connection_status_response?.status;
        } else {
            return Promise.reject();
        }
    }

    public async sendScaleData(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Kassendaten`, data);

            if (response.data.send_Kassendaten_response?.ServiceErrors) {
                throw new Error(response.data.send_Kassendaten_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Kassendaten_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async sendMasterdata(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Stammdaten`, data);

            if (response.data.send_Stammdaten_response?.ServiceErrors) {
                throw new Error(response.data.send_Stammdaten_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Stammdaten_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async sendArticle(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Artikel`, data);

            if (response.data.send_Artikel_response?.ServiceErrors) {
                throw new Error(response.data.send_Artikel_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Artikel_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async sendArticleList(selected:{RecordId:number}[]): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Selected: selected,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_ArtikelList`, data);

            if (response.data.send_ArtikelList_response?.ServiceErrors) {
                throw new Error(response.data.send_ArtikelList_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_ArtikelList_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async isTransmitting(): Promise<boolean> {
        return await this.getConnectionStatus() !== 1;
    }



    public async getReports(): Promise<any[]> {
        //https://portal.aprocon.de:44380/POSManager?get_POSManagerReports
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserID: userId,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_POSManagerReports`, data);

            if (response.data.get_POSManagerReports_response?.ServiceErrors) {
                throw new Error(response.data.get_POSManagerReports_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            // nicht verbunden=0,
            // inaktiv=1,
            // aktiv=2,
            // Connect-Dienst nicht bereit=3

            return response.data.get_POSManagerReports_response?.POSManReports;
        } else {
            return Promise.reject();
        }
    }
    public async getReport(args: { reportNr: number, start: Date, end: Date }): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                ReportNr: args.reportNr,
                DatumVon: moment(args.start).format("YYYY/MM/DD"),
                DatumBis: moment(args.end).format("YYYY/MM/DD")
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerReport`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerReport_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerReport_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_POSManagerReport_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }
    public getSalesGridList(): any[] {
        const sid = this.auth.getAuthToken();
        const user = this.auth.getUserId();
        if (sid) {
            const tabs = [];
            const apiTabs = this.salesList;
            for (let i = 0; i < apiTabs.length; i++) {
                // @ts-ignore
                tabs.push({
                    id: i,
                    route: `/umsaetze/${i}`,
                    name: apiTabs[i].LABEL,
                    icon: apiTabs[i].ICON,
                    gridName: apiTabs[i].GRIDNAME,
                    formName: apiTabs[i].FORMNAME,
                })
            }

            return tabs;
            // const response = await axios.post(`${this.auth.getServiceUrl()}/Stammdaten/Stammdaten_GetGridList`, {
            //     SID: sid,
            //     UserId: user
            // });
            // if (response.data.Stammdaten_GetGridList_response?.ServiceErrors) {
            //     throw new Error(response.data.Stammdaten_GetGridList_response.ServiceErrors[0].ERRORRECOMMENDATION);
            // } else {
            //     const tabs = new Array();
            //     const apiTabs = response.data.Stammdaten_GetGridList_response;
            //     for (let i = 0; i < apiTabs.length; i++) {
            //         tabs.push({
            //             id: i,
            //             route: `/stammdaten/${i}`,
            //             name: apiTabs[i].LABEL,
            //             icon: apiTabs[i].ICON,
            //             gridName: apiTabs[i].GRIDNAME,
            //             formName: apiTabs[i].FORMNAME,
            //         })
            //     }
            //
            //     return tabs;
        }
        return [];
    }

    public async getCashReport(date:string, id:number=-1): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {

            const data = {
                SID: sid,
                Filiale: branchId,
                Datum: date,
                KassenberichtRecordID:id,
                UserId: userId
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerKassenbericht`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerKassenbericht_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerKassenbericht_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_POSManagerKassenbericht_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }
    public async getBonReport(id:number=-1): Promise<string> {
        const sid = this.auth.getAuthToken();
        const userId = this.auth.getUserId();
        if (sid) {

            const data = {
                SID: sid,
                UserId: userId,
                BonID: id
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerBon`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerBon_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerBon_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_POSManagerBon_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }

    public async getBadges(appId: string): Promise<{APPID:string, APPAREA:string,COLOR:string,BADGETEXT:string,EXTENSIVETEXT:string}[] |undefined> {
        const responseType= "getBadgeMessages_response";
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        //getBadgeMessages
        if (sid) {

            const data = {
                SID: sid,
                AppID: appId,
                UserD: userId,
                Filiale: branchId

            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/Common/getBadgeMessages`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data[responseType]?.ServiceErrors) {
                throw new Error(response.data[responseType].ServiceErrors[0].ERRORRECOMMENDATION);
            }
           // console.log(response.data.getLicensedModules_response);
            return response.data[responseType].BadgeMessages;
        }
        //    return [
        //         {
        //             appAreaId: 1,
        //             smallText: new Date().toLocaleTimeString("de-DE",  {  second:'numeric' }) + " - " + 1,
        //             text:'long text',
        //             color: 'aprocon-green'
        //         },
        //         {
        //             appAreaId: 2,
        //             smallText: new Date().toLocaleTimeString("de-DE",  {  second:'numeric' }) + " - " + 2,
        //             text:'long text',
        //             color: 'aprocon-red'
        //         }];
        // }
    }

    public async getProductReport(args: { reportNr: number; start: Date; end: Date }): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                ReportNr: args.reportNr,
                DatumVon: moment(args.start).format("YYYY/MM/DD"),
                DatumBis: moment(args.end).format("YYYY/MM/DD")
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerReport`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerReport_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerReport_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_POSManagerReport_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }

    public async getProductReports(id: number): Promise<any[]> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserID: userId,
                Filiale: branchId,
                id: id
            }
            // const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_POSManagerReports`, data);
            //
            // if (response.data.get_POSManagerReports_response?.ServiceErrors) {
            //     throw new Error(response.data.get_POSManagerReports_response.ServiceErrors[0].ERRORRECOMMENDATION);
            // }
            // // nicht verbunden=0,
            // // inaktiv=1,
            // // aktiv=2,
            // // Connect-Dienst nicht bereit=3
            //
            // return response.data.get_POSManagerReports_response?.POSManReports;
            return [{
                "REPORTNR": 1,
                "HEADER": "Kundengruppenbericht",
                "DESCRIPTION": "Auswertung der Kundengruppen im wählbaren Zeitraum",
                "ICON_SVG": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 1.1//EN\" \"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\">\n<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"32px\" height=\"32px\" viewBox=\"0 0 32 32\" enable-background=\"new 0 0 32 32\" xml:space=\"preserve\">\n  <g id=\"icon\">\n    <path d=\"M30,30H4v-4H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2V8h2V6H2V4h2V0h26V30zM6,28h22V2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6V28z\" fill=\"#3C3C3C\"/>\n    <polygon points=\"11.618,24.685 10.077,23.41 14.523,18.039 18.714,20.952 21.509,17.325 19.335,17.703 18.993,15.733 24.746,14.733 25.385,20.438 23.397,20.661 23.152,18.47 19.136,23.68 14.891,20.731\" fill=\"#3C3C3C\" stroke-linejoin=\"miter\"/>\n    <rect x=\"11\" y=\"4\" width=\"14\" height=\"2\" fill=\"#3C3C3C\"/>\n    <rect x=\"13\" y=\"8\" width=\"10\" height=\"2\" fill=\"#3C3C3C\"/>\n  <\/g>\n<\/svg>\n",
                "PERIOD": 4
            },
                {
                    "REPORTNR": 2,
                    "HEADER": "Monatsbericht",
                    "DESCRIPTION": "Monatsbericht des Artikels",
                    "ICON_SVG": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 1.1//EN\" \"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\">\n<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"32px\" height=\"32px\" viewBox=\"0 0 32 32\" enable-background=\"new 0 0 32 32\" xml:space=\"preserve\">\n  <g id=\"icon\">\n    <path d=\"M30,30H4v-4H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2v-2h2v-2H2V8h2V6H2V4h2V0h26V30zM6,28h22V2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6v2h2v2H6V28z\" fill=\"#3C3C3C\"/>\n    <polygon points=\"11.618,24.685 10.077,23.41 14.523,18.039 18.714,20.952 21.509,17.325 19.335,17.703 18.993,15.733 24.746,14.733 25.385,20.438 23.397,20.661 23.152,18.47 19.136,23.68 14.891,20.731\" fill=\"#3C3C3C\" stroke-linejoin=\"miter\"/>\n    <rect x=\"11\" y=\"4\" width=\"14\" height=\"2\" fill=\"#3C3C3C\"/>\n    <rect x=\"13\" y=\"8\" width=\"10\" height=\"2\" fill=\"#3C3C3C\"/>\n  <\/g>\n<\/svg>\n",
                    "PERIOD": 3
                }];
        } else {
            return Promise.reject();
        }
    }

    //POSManager?receive_DeliveryNotes
    public async receiveDeliveryNotes(): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        //getBadgeMessages
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/receive_DeliveryNotes`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.receive_DeliveryNotes_response) {
                if(response.data.receive_DeliveryNotes_response.ServiceErrors) {
                    throw new Error(response.data.receive_DeliveryNotes_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
            }
            return response.data.receive_DeliveryNotes_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async goodsReceipts(date:string): Promise<any> {
        const formattedDate = moment(new Date(),"DD.MM.YYYY").format("YYYY/MM/DD");
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        //getBadgeMessages
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Datum: formattedDate
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/Lst_Warenbons`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.Lst_Warenbons_response) {
                if(response.data.Lst_Warenbons_response.ServiceErrors) {
                    throw new Error(response.data.Lst_Warenbons_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
                if(response.data.Lst_Warenbons_response.Warenbons) {
                    return response.data.Lst_Warenbons_response.Warenbons.map((item:any)=>{
                        return item;
                    });
                }
            }
            return Promise.reject();

        } else {
            return Promise.reject();
        }

    }

    public async createOrderByArticles(ids:{Nummer:number}[]): Promise<any> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Stammartikel: ids
            };

            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Bestellung_aus_Stammartikeln`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});

            if (response.data.Bestellung_aus_Stammartikeln_response) {
                if(response.data.Bestellung_aus_Stammartikeln_response.ServiceErrors) {
                    throw new Error(response.data.Bestellung_aus_Stammartikeln_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }

                return response.data.Bestellung_aus_Stammartikeln_response;
            }
            return Promise.reject();
        } else {
            return Promise.reject();
        }
    }
    public async createDeliveryNotes(ids:number[]): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Auftragsnummern: ids
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Lieferschein_erstellen`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.Lieferschein_erstellen_response) {
                if(response.data.Lieferschein_erstellen_response.ServiceErrors) {
                    throw new Error(response.data.Lieferschein_erstellen_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
                return response.data.Lieferschein_erstellen_response.UserMessage;
            }
            return Promise.reject();
        } else {
            return Promise.reject();
        }
    }
    public async cashUpBills(ids:number[]): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Rechnungsnummern: ids
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Rechnung_bezahlt`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.Rechnung_bezahlt_response) {
                if(response.data.Rechnung_bezahlt_response.ServiceErrors) {
                    throw new Error(response.data.Rechnung_bezahlt_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
                return response.data.Rechnung_bezahlt_response.UserMessage;
            }
            return Promise.reject();
        } else {
            return Promise.reject();
        }
    }
    public async cashUpDeliveryNotes(ids:number[]): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Auftragsnummern: ids
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Lieferschein_abrechnen`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.Lieferschein_abrechnen_response) {
                if(response.data.Lieferschein_abrechnen_response.ServiceErrors) {
                    throw new Error(response.data.Lieferschein_abrechnen_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
                return response.data.Lieferschein_abrechnen_response.UserMessage;
            }
            return Promise.reject();

        } else {
            return Promise.reject();
        }
    }

    public async pickUpReady(ids:number[]): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Auftragsnummern: ids
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Bestellungen_abholbereit`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.Bestellungen_abholbereit_response) {
                if(response.data.Bestellungen_abholbereit_response.ServiceErrors) {
                    throw new Error(response.data.Bestellungen_abholbereit_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
                return response.data.Bestellungen_abholbereit_response.UserMessage;
            }
            return Promise.reject();

        } else {
            return Promise.reject();
        }
    }
    public async acceptOrderQuantities(ids:{Nummer:number}[]): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId= this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Positionsnummern: ids
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Liefermengen_uebernehmen`, data, {timeoutErrorMessage: 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.Liefermengen_uebernehmen_response) {
                if(response.data.Liefermengen_uebernehmen_response.ServiceErrors) {
                    throw new Error(response.data.Liefermengen_uebernehmen_response.ServiceErrors[0].ERRORRECOMMENDATION);
                }
                return response.data.Liefermengen_uebernehmen_response.UserMessage;
            }
            return Promise.reject();

        } else {
            return Promise.reject();
        }
    }
    public async receiptGoodReceipt(recordId: number, date:string, goodReceipt:number): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                UserID: userId,
                Auftragsnummer: recordId,
                WarenbonNummer: goodReceipt,
                WarenbonDatum: date
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/Warenbon_uebernehmen`, data);
            if (response.data.Warenbon_uebernehmen_response?.ServiceErrors) {
                throw new Error(response.data.Warenbon_uebernehmen_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.Warenbon_uebernehmen_response.UserMessage;
        } else {
            return Promise.reject();
        }
    }

    public async getOrderManagerReport(id:string): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserId: userId,
                AuftragID: id
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/get_URL_OrderManagerAuftrag`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_OrderManagerAuftrag_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_OrderManagerAuftrag_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_OrderManagerAuftrag_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }

    public async getOrderManagerReportInvoice(id:string): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserId: userId,
                RechnungID: id
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/OrderManager/get_URL_OrderManagerRechnung`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_OrderManagerRechnung_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_OrderManagerRechnung_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_OrderManagerRechnung_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }
}
